import ods from '../ods.json';

import { useEffect, useState } from 'react';
import { CircleContainer } from '../components/Graph';
import { StrategieGoals } from '../components/StrategieGoals';
import { Breadcrumb } from '../components/BreadCrumb';
import { BulletListItem } from '../types';
import { useSearchParams } from 'react-router-dom';
import Swal from 'sweetalert2';

export const HomeView = () => {
  const DEFAULT_SELECTED_ODS = '';
  const [selectedOds, setSelectedOds] = useState<string>(DEFAULT_SELECTED_ODS);
  const [strategicPlansData, setStrategicPlansData] = useState<any>({});
  const [selectedIdx, setSelectedIdx] = useState<number>();
  const [selectedActive, setSelectedActive] = useState<string>('');
  const [selectedActiveIdx, setSelectedActiveIdx] = useState<number>();
  const [searchParams, setSearchParams] = useSearchParams();
  const selectedPlan = searchParams.get('plan');

  const isActive = (odName: string): boolean => {
    return !selectedActive || odName === selectedActive;
  };
  const getOds = (arr: any) => {
    const odss: BulletListItem[] = [];
    if (!arr) {
      return [];
    }
    arr.forEach((arrItem: any) => {
      arrItem.ods.forEach((od: any) => {
        odss.push({
          phrase: od.ods,
          active: isActive(arrItem.name),
          url: od.url,
        });
      });
    });
    return odss;
  };
  const getActives = (arr: any) => {
    if (!arr) {
      return [];
    }
    return arr.map((active: any, idx: number) => ({
      phrase: active.name,
      active:
        selectedActiveIdx !== undefined ? idx === selectedActiveIdx : true,
    }));
  };

  const getAudits = (arr: any) => {
    if (!arr) {
      return [];
    }
    const audits: any = [];
    arr.forEach((audit: any) => {
      audit.sigc.forEach((audit2: any) => {
        audits.push({
          phrase: audit2.criteria,
          active: isActive(audit.name),
          tooltip: `${audit2.id.includes('.') ? 'CRITERIO ' : 'DIRECTRIZ '}${
            audit2.id
          }. ${audit2.criteria}\n${audit2.description}`,
        });
      });
    });
    return audits;
  };

  const createStrategicPlansData = () => {
    setStrategicPlansData(
      ods.reduce((acc: any, od: any) => {
        if (!acc[od.subcategory]) {
          acc[od.subcategory] = [];
        }
        acc[od.subcategory].push({
          title: od.title,
          ods: getOds(od.assets),
          actives: getActives(od.assets),
          audits: getAudits(od.assets),
          description: od.description,
        });
        return acc;
      }, {})
    );
  };

  useEffect(() => {
    const modalShown = localStorage.getItem('modalShown');
    if (!modalShown) {
      Swal.fire({
        title: 'Bienvenida/o al Cuadro de Mando Integral de la UFPC',
        html: `<div>
            <p>
              La Universidad Fernando Pessoa Canarias le invita a visualizar el
              esfuerzo de nuestra comunidad
            </p>
            <p>
              integrada por Personal Docente e Investigador, Personal Técnico,
              de Gestión y Administración y Servicios, así como Estudiantado por
              una Universidad conectada y comprometida con el impacto social y
              medioambiental desde el Sistema Interno de calidad (SiQ).
            </p>
            <p>
              Para acceder a los contenidos de cada elemento sitúe el cursor
              encima del texto correspondiente
            </p>
          </div>`,
        confirmButtonText: 'OK',
      }).then(() => {
        localStorage.setItem('modalShown', 'true');
      });
    }
    createStrategicPlansData();
  }, []);

  useEffect(() => {
    if (strategicPlansData && Object.keys(strategicPlansData).length > 0) {
      if (selectedPlan !== undefined && selectedPlan !== null) {
        const planBySelectedPlan =
          Object.keys(strategicPlansData)[parseInt(selectedPlan)];
        setSelectedOds(planBySelectedPlan);
      }
    }
  }, [strategicPlansData]);

  useEffect(() => {
    if (selectedOds === DEFAULT_SELECTED_ODS) {
      setSelectedIdx(undefined);
    }
    setSelectedActive('');
    setSelectedActiveIdx(undefined);
  }, [selectedOds]);

  useEffect(() => {
    createStrategicPlansData();
  }, [selectedActive]);

  const getObjectiveTitles = (subcategory: string) => {
    return strategicPlansData[subcategory].map((objective: any) => ({
      phrase: objective.title,
      tooltip: objective.description,
    }));
  };

  const getGraphData = (): { phrase: string; title: string }[] => {
    if (selectedOds !== DEFAULT_SELECTED_ODS) {
      return getObjectiveTitles(selectedOds);
    }
    return Object.keys(strategicPlansData).map((key) => ({
      phrase: key,
      title: key,
    }));
  };

  const getSortAndUniquePhrases = (arr: any) => {
    return arr
      .sort((a: any, b: any) => {
        return b.active - a.active;
      })
      .reduce((acc: any, act: any) => {
        if (!acc.find((a: any) => a.phrase === act.phrase)) {
          acc.push(act);
        }
        return acc;
      }, []);
  };

  const onSelectOds = (ods: string) => {
    if (selectedOds !== DEFAULT_SELECTED_ODS) {
      setSelectedIdx(getGraphData().findIndex((data) => data.phrase === ods));
    } else {
      setSelectedOds(ods);
    }
    setSelectedActive('');
    setSelectedActiveIdx(undefined);
  };

  const getStrategicGoals = () => {
    return [
      {
        title: 'Activos',
        goals: strategicPlansData[selectedOds][selectedIdx!].actives.reduce(
          (acc: any, act: any) => {
            if (!acc.find((a: any) => a.phrase === act.phrase)) {
              acc.push(act);
            }
            return acc;
          },
          []
        ),
        onSelected: (selected: number) => {
          setSelectedActive(
            strategicPlansData[selectedOds][selectedIdx!].actives[selected]
              .phrase
          );
          setSelectedActiveIdx(selected);
        },
        selectedIndex: selectedActiveIdx,
      },
      {
        titleIcon: '/images/sdg_wheel.png',
        title: 'ODS y metas',
        goals: getSortAndUniquePhrases(
          strategicPlansData[selectedOds][selectedIdx!].ods
        ),
        onSelected: (selected: number) => {
          window.open(
            getSortAndUniquePhrases(
              strategicPlansData[selectedOds][selectedIdx!].ods
            )[selected].url,
            '_blank'
          );
          strategicPlansData[selectedOds][selectedIdx!].ods[selected].phrase;
        },
      },
      {
        title: 'Criterios y directrices AUDIT',
        goals: strategicPlansData[selectedOds][selectedIdx!].audits
          .sort((a: any, b: any) => {
            return b.active - a.active;
          })
          .reduce((acc: any, act: any) => {
            if (!acc.find((a: any) => a.phrase === act.phrase)) {
              acc.push(act);
            }
            return acc;
          }, []),
      },
    ];
  };

  const getBreadcrumbSections = () => {
    const breadCrumbs = [
      {
        name: 'Plan estratégico',
        action: () => {
          setSelectedOds(DEFAULT_SELECTED_ODS);
          window.history.pushState({}, '', '/plan-estrategico');
        },
      },
    ];
    if (selectedOds !== DEFAULT_SELECTED_ODS) {
      breadCrumbs.push({
        name: selectedOds,
        action: () => {
          setSelectedOds(selectedOds);
          setSelectedIdx(undefined);
        },
      });
      if (selectedIdx !== undefined) {
        breadCrumbs.push({
          name: getGraphData()[selectedIdx].phrase,
          action: () => {},
        });
      }
    }
    return breadCrumbs;
  };

  return (
    <>
      <div className="flex md:hidden h-[100vh] w-[100vw] flex items-center justify-center">
        <p>Esta página es solo visible en versión escritorio</p>
      </div>
      <section className="hidden md:flex self-stretch flex-col items-start justify-start pt-0   box-border gap-4 max-w-full w-[100%] h-[calc(100%-15px-1px)] text-left text-sm font-body-regular">
        <div className="h-[80px] flex items-center border-b-[1px] border-solid border-[#E5E7EB] w-[calc(100%-22px)] pl-[22px] flex-row">
          <img
            src="/images/logopessoa.png"
            alt=""
            className="h-[40px] my-auto mr-[50px]"
          />
          <div>
            <Breadcrumb sections={getBreadcrumbSections()}></Breadcrumb>
          </div>
          <div className="ml-auto mr-[20px] pt-[25px]">
            <img
              src="/images/universidad_fernando_pessoa_si_10_aniversario.webp"
              className="my-auto ml-auto h-[65px]"
              alt=""
            />
          </div>
        </div>
        <div className="fade-in flex justify-between w-[100%] my-auto">
          <div
            className={`flex-1 flex justify-center h-[calc(100vh-80px-1rem-1px)] items-center relative ${
              selectedIdx !== undefined ? 'text-[15px]' : 'text-[16px]'
            }`}
          >
            <CircleContainer
              centeredPhrase={selectedOds}
              onSelect={onSelectOds}
              phrases={getGraphData()}
              selectedIdx={selectedIdx}
            ></CircleContainer>
          </div>
          {selectedIdx !== undefined &&
            selectedOds !== DEFAULT_SELECTED_ODS && (
              <div className="fade-in mb-[15px] flex-[0.75] flex items-center pt-[50px] pb-[50px]">
                <StrategieGoals
                  strategicGoals={getStrategicGoals()}
                ></StrategieGoals>
              </div>
            )}
        </div>
      </section>
    </>
  );
};
