import { BulletListItem } from '../types';
import { BulletList } from './BulletList';

type StrategieGoalProps = {
  strategicGoals: {
    title: string;
    goals: BulletListItem[];
    onSelected?: (selected: number) => void;
    selectedIndex?: number;
    titleIcon?: string;
  }[];
};

export const StrategieGoals = ({ strategicGoals }: StrategieGoalProps) => {
  return (
    <>
      <div className="w-fit h-fit flex flex-col gap-[50px]">
        {strategicGoals.length > 0
          ? strategicGoals.map((goal) => (
              <div key={goal.title}>
                {goal.title && goal.goals.length > 0 ? (
                  <BulletList
                    title={goal.title}
                    titleIcon={goal.titleIcon}
                    items={goal.goals}
                    onSelected={goal.onSelected}
                    selectedIndex={goal.selectedIndex}
                  ></BulletList>
                ) : null}
              </div>
            ))
          : null}
      </div>
    </>
  );
};
