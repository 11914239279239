import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Answers, Form } from '../types';

interface DataState {
  data: Form | null;
}

const initialState: DataState = {
  data: null,
};

export const dataSlice = createSlice({
  name: 'data',
  initialState,
  reducers: {
    setData: (state, action: PayloadAction<Form>) => {
      state.data = action.payload;
    },
    setAnswers: (state, action: PayloadAction<Answers>) => {
      if (state.data) {
        state.data.answers = { ...state.data.answers, ...action.payload };
      }
    },
    resetAnswers: (state) => {
      if (state.data) {
        state.data.answers = {
          id: '',
          ods: [],
          odsNotes: '',
          auditNotes: '',
          objectivesNotes: '',
        };
      }
    },
    setFiles: (state, action: PayloadAction<File[]>) => {
      if (state.data) {
        if (!state.data.files) {
          state.data.files = [];
        }
        const uniqueFiles = action.payload.filter(
          (file) => !(state.data?.files || []).some((f) => f.name === file.name)
        );
        state.data.files.push(...uniqueFiles);
      }
    },
    removeFile: (state, action: PayloadAction<number>) => {
      if (state.data) {
        state.data.files = state.data.files
          .slice(0, action.payload)
          .concat(state.data.files.slice(action.payload + 1));
      }
    },
    setFilesToDelete: (state, action: PayloadAction<string>) => {
      if (state.data) {
        if (!state.data.filesToDelete) {
          state.data.filesToDelete = [];
        }
        state.data.filesToDelete.push(action.payload);
      }
    },
  },
});

export const { setData, setAnswers, resetAnswers, setFiles, removeFile, setFilesToDelete } =
  dataSlice.actions;

export default dataSlice.reducer;
