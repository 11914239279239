import { ChangeEvent, useState } from 'react';

interface ObservationModalProps {
  isOpen: boolean;
  currentValue: string;
  toggleModal: (open: boolean) => void;
  handleAccept: (observation: string) => void;
}

export const ObservationModal = ({
  isOpen,
  currentValue,
  toggleModal,
  handleAccept,
}: ObservationModalProps) => {
  const [observation, setObservation] = useState<string>(currentValue);
  const handleClose = () => {
    toggleModal(false);
  };

  const handleInputChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setObservation(event.target.value);
  };

  return (
    <>
      {isOpen && (
        <div className="flex justify-center items-center fixed h-screen w-full z-8">
          <div className="flex items-center justify-center min-h-screen">
            <div
              className="fixed inset-0 transition-opacity"
              aria-hidden="true"
            >
              <div className="absolute inset-0 bg-gray-500 animate-fade-in-modal opacity-75"></div>
            </div>
            <div className="flex flex-col bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all w-full min-w-full max-w-[750px] px-9 py-10 gap-10 animate-fade-in-down">
              <div className="flex flex-col gap-4">
                <div>
                  <span
                    className="absolute rounded-full bg-[#E5E5E5] p-1 w-6 h-6 flex justify-center items-center cursor-pointer top-2 right-2"
                    onClick={handleClose}
                  >
                    ​<img src="/images/close.svg" alt="Close" />
                  </span>
                  <h4 className="m-0 text-[#54595E] font-body-regular font-semibold leading-7 text-xl">
                    Añada aquí las notas que crea pertinentes
                  </h4>
                </div>
                <div className="self-stretch flex-1 rounded-lg bg-white flex flex-col items-center justify-start border-[1px] border-solid border-gainsboro">
                  <textarea
                    className="border-none outline-none font-medium font-body-regular text-sm bg-transparent self-stretch tracking-[0.01em] leading-[26px] text-left flex items-center h-[58px] shrink-0 min-w-[250px] h-full w-full box-border p-4 resize-none text-[#4B4B4B]"
                    placeholder="Indique aquí sus notas"
                    value={observation}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div className="flex items-center justify-end px-0 gap-5">
                <button
                  className="cursor-pointer px-[16px] py-[8px] bg-white rounded-10xs shadow-[0px_1px_2px_rgba(38,_110,_255,_0.05)] box-border flex flex-row items-center justify-center border-[1px] border-solid border-primary-green active:box-border active:border-[1px] active:border-solid active:border-primary-green"
                  onClick={handleClose}
                >
                  <span className="text-base font-semibold font-body-regular text-primary-green text-left">
                    Cancelar
                  </span>
                </button>

                <button
                  className="cursor-pointer px-[16px] py-[8px] bg-primary-green rounded-10xs shadow-[0px_1px_2px_rgba(38,_110,_255,_0.05)] box-border flex flex-row items-center justify-center border-[1px] border-solid border-primary-green active:bg-primary-green active:box-border active:border-[1px] active:border-solid active:border-primary-green"
                  onClick={() => handleAccept(observation)}
                >
                  <span className="text-base font-semibold font-body-regular text-white text-left">
                    Aceptar
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
