import { useState } from "react";

type ItemContainerProps = {
  title: string;
  description: string;
  showInput?: boolean;
  inputEnabled?: boolean;
  checked?: boolean;
  onCheckedChange?: (checked: boolean) => void;
};

export const ItemContainer = ({
  title,
  description,
  showInput = true,
  inputEnabled = true,
  checked,
  onCheckedChange,
}: ItemContainerProps) => {
  const [isChecked, setChecked] = useState(checked);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
    if (onCheckedChange) {
      onCheckedChange(event.target.checked);
    }
  };

  return (
    <div className="self-stretch flex flex-row items-start justify-start py-0 px-0 box-border max-w-full w-auto h-auto gap-0 [transform:rotate(0deg)]">
      <div className="flex-1 flex flex-col items-start justify-start pt-0 px-0 pb-0.5 box-border max-w-full h-auto gap-0">
        <div className="flex flex-row items-center justify-start gap-[8px]">
          {showInput && (
            <input
              className="m-0 w-5 h-5 cursor-pointer"
              type="checkbox"
              disabled={!inputEnabled}
              checked={isChecked}
              onChange={handleInputChange}
            />
          )}
          <div className="h-6 flex flex-col items-start justify-end pt-0 px-0 pb-0 box-border">
            <div className="h-[29px] flex flex-col items-start justify-start pt-0 px-0 pb-0 box-border">
              <div className="tracking-[0.01em] leading-[34px] font-semibold text-transparent !bg-clip-text [background:linear-gradient(rgba(0,_0,_0,_0.2),_rgba(0,_0,_0,_0.2)),_linear-gradient(rgba(0,_0,_0,_0.2),_rgba(0,_0,_0,_0.2)),_linear-gradient(rgba(0,_0,_0,_0.2),_rgba(0,_0,_0,_0.2)),_linear-gradient(rgba(0,_0,_0,_0.2),_rgba(0,_0,_0,_0.2)),_#323232] [-webkit-background-clip:text] [-webkit-text-fill-color:transparent]">
                {title}
              </div>
            </div>
          </div>
        </div>
        <div
          className={`self-stretch flex flex-row items-start justify-start py-0 pr-0 box-border max-w-full w-auto h-auto gap-0 [transform:rotate(0deg)] ${
            showInput ? "pl-7" : ""
          }`}
        >
          <div className="flex-1 flex flex-row items-center justify-center max-w-full h-auto gap-[10px]">
            <div className="flex-1 tracking-[0.01em] leading-[26px] font-medium text-transparent !bg-clip-text [background:linear-gradient(rgba(0,_0,_0,_0.2),_rgba(0,_0,_0,_0.2)),_linear-gradient(rgba(0,_0,_0,_0.2),_rgba(0,_0,_0,_0.2)),_linear-gradient(rgba(0,_0,_0,_0.2),_rgba(0,_0,_0,_0.2)),_linear-gradient(rgba(0,_0,_0,_0.2),_rgba(0,_0,_0,_0.2)),_#323232] [-webkit-background-clip:text] [-webkit-text-fill-color:transparent] flex max-w-full hover:font-medium">
              {description}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
