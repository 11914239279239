import CardContainer from '../components/CardContainer';
import Header from '../components/Header';
import {
  AUDIT_CRITERIA_AND_GUIDELINES,
  STRATEGIC_OBJECTIVES,
  SUSTAINABLE_DEVELOPMENT_GOALS,
} from '../constants';
import { CardType, FormStatus, SectionType, UserRole } from '../enums';
import {
  removeFile,
  resetAnswers,
  setFilesToDelete,
  setAnswers,
  setData,
  setFiles,
} from '../features/dataSlice';
import { formService } from '../services/form.service';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { setLoading } from '../features/loadingSlice';
import { FileUploaded, Form } from '../types';
import { Observation } from '../components/Observations';
import { toast } from 'sonner';
import { ApprovalModal } from '../components/ApprovalModal';
import { utilsService } from '../services/utils.service';
import { InfoModal } from '../components/InfoModal';
import { ObservationModal } from '../components/ObservationModal';
import { GenericInfoModal } from '../components/GenericInfoModal';
import MyDropzone from '../components/Dropzone';

export const FormView = () => {
  const { id } = useParams<{ id: string }>();
  const dispatch = useDispatch();
  const [form, setForm] = useState<Form | null>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [status, setStatus] = useState<FormStatus>(FormStatus.Progress);
  const { data } = useSelector((state: any) => state.data);
  const [observation, setObservation] = useState<string>('');
  const [verifierObservation, setVerifierObservation] = useState<string>('');
  const [infoIsOpen, setInfoIsOpen] = useState(false);
  const [observationModalType, setObservationModalType] = useState<CardType>();
  const [genericInfoModalInfo, setGenericInfoModalInfo] = useState<
    { title: string; description: string } | undefined
  >(undefined);

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  const isMatchingForm = (data: Form, form: Form) => {
    return (
      !(form?.role === UserRole.Verifier) &&
      data &&
      'answers' in data &&
      'id' in data.answers &&
      form.id.toString() === data.answers.id
    );
  };

  useEffect(() => {
    const fetchForm = async () => {
      try {
        dispatch(setLoading(true));
        const form = (await formService.getFormById(id as string)).data;
        if (isMatchingForm(data, form)) {
          form.answers = {
            ...data.answers,
            verifierObservation: form?.answers?.verifierObservation,
          };
          form.files = data.files;
        }
        form.audits = utilsService.orderArrayByField(form.audits, 'id');
        form.ods = utilsService.orderArrayByField(form.ods, 'id');
        form.objectives = utilsService.orderArrayByField(form.objectives, 'id');
        dispatch(setData(form));
        setForm(form);
        setObservation(form.answers?.observation);
        setVerifierObservation(form.answers?.verifierObservation);
      } catch (error) {
        setForm(null);
      } finally {
        dispatch(setLoading(false));
      }
    };

    fetchForm();
  }, [id, dispatch]);

  const getObservationModalAcceptHandler = () => {
    switch (observationModalType) {
      case CardType.ODS:
        return (observation: string) => {
          const answers = JSON.parse(JSON.stringify(data.answers));
          answers.odsNotes = observation;
          setObservationModalType(undefined);
          dispatch(setAnswers(answers));
        };
      case CardType.AUDIT:
        return (observation: string) => {
          const answers = JSON.parse(JSON.stringify(data.answers));
          answers.auditNotes = observation;
          setObservationModalType(undefined);
          dispatch(setAnswers(answers));
        };
      case CardType.OBJECTIVES:
        return (observation: string) => {
          const answers = JSON.parse(JSON.stringify(data.answers));
          answers.objectivesNotes = observation;
          setObservationModalType(undefined);
          dispatch(setAnswers(answers));
        };
      default:
        return (observation: string) => {};
    }
  };

  const getObservationModalCurrentValue = () => {
    switch (observationModalType) {
      case CardType.ODS:
        return data.answers?.odsNotes || '';
      case CardType.AUDIT:
        return data.answers?.auditNotes || '';
      case CardType.OBJECTIVES:
        return data.answers?.objectivesNotes || '';
      default:
        return '';
    }
  };

  const getObservationModal = () => {
    return (
      <ObservationModal
        isOpen={!!observationModalType}
        currentValue={getObservationModalCurrentValue()}
        toggleModal={(open: boolean) => {
          setObservationModalType(undefined);
        }}
        handleAccept={getObservationModalAcceptHandler()}
      />
    );
  };

  const isVerifier = () => form?.role === UserRole.Verifier;
  const isEnabledResponsible = () =>
    !isVerifier() &&
    (form?.state === FormStatus.Pending || form?.state === FormStatus.Rejected);

  const handleObservationChange = (newObservation: string) => {
    const answers = JSON.parse(JSON.stringify(data.answers));
    answers.observation = newObservation;
    setObservation(newObservation);
    try {
      dispatch(setAnswers(answers));
    } catch (error) {
      toast.error('Hubo un error al guardar la observación.');
    }
  };

  const saveOrApprove = () => {
    if (isEnabledResponsible()) {
      saveAnswers();
    } else if (!isEnabledResponsible() && isVerifier()) {
      setStatus(FormStatus.Completed);
      toggleModal();
    }
  };

  const rejected = () => {
    setStatus(FormStatus.Rejected);
    toggleModal();
  };

  const saveAnswers = async () => {
    try {
      dispatch(setLoading(true));
      console.log('data', data);
      const formData = new FormData();
      const filesToUpload = (data.files || []).filter(
        (file: File | FileUploaded) => file instanceof File
      );
      filesToUpload.forEach((file: File) => {
        formData.append('files', file);
      });
      formData.append('id', data.id);
      for (const key in data.answers) {
        if (key === 'id' || key === 'filesToDelete') continue;
        if (typeof data[key] === 'object') {
          formData.append(key, JSON.stringify(data.answers[key]));
        } else {
          formData.append(key, data.answers[key] || '');
        }
      }
      formData.append(
        'filesToDelete',
        JSON.stringify(data?.filesToDelete || [])
      );
      await formService.saveAnswers(formData);
      dispatch(resetAnswers());
      window.location.reload();
    } catch (error) {
      toast.error('Hubo un error al guardar las respuestas.');
    } finally {
      dispatch(setLoading(false));
    }
  };

  const setGenericInfo = (cardType: CardType) => {
    const genericInfo = {
      title: 'Información sobre el Activo',
      description: '',
    };
    switch (cardType) {
      case CardType.ODS:
        genericInfo.description = data.answers.odsNotes || '';
        break;
      case CardType.AUDIT:
        genericInfo.description = data.answers.auditNotes || '';
        break;
      case CardType.OBJECTIVES:
        genericInfo.description = data.answers.objectivesNotes || '';
        break;
      default:
        break;
    }
    setGenericInfoModalInfo(genericInfo);
  };

  const onSelectedFiles = (files: any) => {
    dispatch(setFiles(files));
  };

  const getMainAudits = () => {
    const uniqueAudits =
      form?.audits.filter((audit, idx, self) => {
        return (
          self.findIndex((a) => a.id === audit.id) === idx &&
          !audit.id.split('.')[1]
        );
      }) || [];
    return uniqueAudits || [];
  };

  const onRemoveFile = (
    idx: number,
    file: { name: string; id: string; uploaded: boolean }
  ) => {
    dispatch(removeFile(idx));
    if (file.uploaded && file.id) {
      dispatch(setFilesToDelete(file.id));
    }
  };

  return (
    <>
      {form && (
        <InfoModal
          isOpen={infoIsOpen}
          toggleModal={() => {
            setInfoIsOpen(false);
          }}
          data={{
            period: data.period || '',
            estimatedDeliveryDate: utilsService.formatDate(
              form?.deadline || ''
            ),
            responsible: form?.responsible?.name || '',
            deliveryDate: utilsService.formatDate(form?.deliveredDate || ''),
            validator: form?.verifier?.name || '',
            files: form?.files || [],
          }}
        ></InfoModal>
      )}
      <div className="flex gap-12 w-full h-full min-h-screen flex-col bg-gray-100">
        <div className="w-full h-auto overflow-hidden flex flex-col items-center justify-start py-10 px-5 box-border gap-28 tracking-[normal]">
          {form && id ? (
            <>
              <Header
                section={SectionType.HOME}
                infoAction={() => {
                  setInfoIsOpen(true);
                }}
              />
              <section className="self-stretch overflow-hidden flex flex-wrap flex-row items-start justify-center pt-0 px-5 pb-1 box-border gap-8 max-w-full w-auto h-auto text-left text-mid text-gray-300 font-body-regular">
                <CardContainer
                  formId={id}
                  items={form.ods}
                  title={SUSTAINABLE_DEVELOPMENT_GOALS}
                  type={CardType.ODS}
                  handleAddNote={
                    isEnabledResponsible()
                      ? () => {
                          setObservationModalType(CardType.ODS);
                        }
                      : undefined
                  }
                  handleViewNote={
                    (data.role === UserRole.Verifier ||
                      (data.role === UserRole.Responsible &&
                        data.state === FormStatus.Progress)) &&
                    data.answers.odsNotes
                      ? () => {
                          setGenericInfo(CardType.ODS);
                        }
                      : undefined
                  }
                />
                <CardContainer
                  formId={id}
                  items={getMainAudits()}
                  title={AUDIT_CRITERIA_AND_GUIDELINES}
                  type={CardType.AUDIT}
                  handleAddNote={
                    isEnabledResponsible()
                      ? () => {
                          setObservationModalType(CardType.AUDIT);
                        }
                      : undefined
                  }
                  handleViewNote={
                    (data.role === UserRole.Verifier ||
                      (data.role === UserRole.Responsible &&
                        data.state === FormStatus.Progress)) &&
                    data.answers.auditNotes
                      ? () => {
                          setGenericInfo(CardType.AUDIT);
                        }
                      : undefined
                  }
                />
                <CardContainer
                  formId={id}
                  title={STRATEGIC_OBJECTIVES}
                  items={form.objectives}
                  type={CardType.OBJECTIVES}
                  handleAddNote={
                    isEnabledResponsible()
                      ? () => {
                          setObservationModalType(CardType.OBJECTIVES);
                        }
                      : undefined
                  }
                  handleViewNote={
                    (data.role === UserRole.Verifier ||
                      (data.role === UserRole.Responsible &&
                        data.state === FormStatus.Progress)) &&
                    data.answers.objectivesNotes
                      ? () => {
                          setGenericInfo(CardType.OBJECTIVES);
                        }
                      : undefined
                  }
                />
              </section>
              {isEnabledResponsible() && (
                <section className="self-stretch flex flex-col gap-12 items-start justify-start pt-0 px-5 pb-6 box-border max-w-full w-auto [transform:rotate(0deg)]">
                  <div className="self-stretch flex-1 flex flex-col items-start justify-start gap-[14px] max-w-full h-auto text-left text-smi text-[#A6A9B8] font-body-regular">
                    <div className="self-stretch flex flex-row items-center justify-start py-0 pr-3 pl-0">
                      <div className="flex-1 flex flex-row items-start justify-start">
                        <div className="font-medium">Ficheros</div>
                      </div>
                    </div>
                    <MyDropzone
                      onDrop={(files) => {
                        onSelectedFiles(files);
                      }}
                      selectedFiles={(data.files || []).map((file: any) => ({
                        name: file.name || '',
                        id: file.id,
                        uploaded: !!file.id,
                      }))}
                      onRemove={onRemoveFile}
                      disabled={!isEnabledResponsible()}
                    ></MyDropzone>
                  </div>
                </section>
              )}
              <section className="self-stretch flex flex-col gap-12 items-start justify-start pt-0 px-5 pb-6 box-border max-w-full w-auto [transform:rotate(0deg)]">
                <Observation
                  currentValue={observation}
                  inputEnabled={isEnabledResponsible()}
                  onObservationChange={handleObservationChange}
                />
                {((isVerifier() && form?.state === FormStatus.Progress) ||
                  isEnabledResponsible()) && (
                  <div className="self-stretch flex flex-row items-start justify-end py-0 px-5 gap-5">
                    {isVerifier() && form?.state === FormStatus.Progress && (
                      <button
                        className="cursor-pointer px-[16px] py-[8px] bg-white rounded-10xs shadow-[0px_1px_2px_rgba(38,_110,_255,_0.05)] box-border flex flex-row items-center justify-center border-[1px] border-solid border-primary-green active:box-border active:border-[1px] active:border-solid active:border-primary-green"
                        onClick={rejected}
                      >
                        <span className="text-base font-semibold font-body-regular text-primary-green text-left">
                          Rechazar
                        </span>
                      </button>
                    )}
                    {(isVerifier() && form?.state === FormStatus.Progress) ||
                    isEnabledResponsible() ? (
                      <button
                        className="cursor-pointer px-[16px] py-[8px] bg-primary-green rounded-10xs shadow-[0px_1px_2px_rgba(38,_110,_255,_0.05)] box-border flex flex-row items-center justify-center border-[1px] border-solid border-primary-green active:bg-primary-green active:box-border active:border-[1px] active:border-solid active:border-primary-green"
                        onClick={() => saveOrApprove()}
                      >
                        <span className="text-base font-semibold font-body-regular text-white text-left">
                          {isVerifier() ? 'Aprobar' : 'Enviar'}
                        </span>
                      </button>
                    ) : null}
                  </div>
                )}
              </section>
              <ApprovalModal
                isOpen={isOpen}
                status={status}
                currentValue={verifierObservation}
                formId={data.id}
                toggleModal={toggleModal}
              />
              {observationModalType && getObservationModal()}
              {genericInfoModalInfo && (
                <GenericInfoModal
                  toggleModal={() => setGenericInfoModalInfo(undefined)}
                  title={genericInfoModalInfo.title}
                  description={genericInfoModalInfo.description}
                ></GenericInfoModal>
              )}
            </>
          ) : (
            <>
              <h1>Formulario no encontrado</h1>
            </>
          )}
        </div>
      </div>
    </>
  );
};
