import { ChangeEvent } from 'react';

type ObservationProps = {
  currentValue: string;
  inputEnabled: boolean;
  onObservationChange: (observation: string) => void;
};

export const Observation = ({
  currentValue,
  inputEnabled = true,
  onObservationChange,
}: ObservationProps) => {
  const handleInputChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    onObservationChange(event.target.value);
  };

  return (
    <div className="self-stretch flex-1 flex flex-col items-start justify-start gap-[14px] max-w-full h-auto text-left text-smi text-[#A6A9B8] font-body-regular">
      <div className="w-[158px] flex flex-col items-start justify-start">
        <div className="self-stretch flex flex-row items-center justify-start py-0 pr-3 pl-0">
          <div className="flex-1 flex flex-row items-start justify-start">
            <div className="font-medium">Justificación</div>
          </div>
        </div>
      </div>
      <div className="self-stretch flex-1 rounded-lg bg-white flex flex-col items-center justify-start border-[1px] border-solid border-gainsboro">
        <textarea
          className={`border-none outline-none font-medium font-body-regular text-sm bg-transparent self-stretch tracking-[0.01em] leading-[26px] text-left flex items-center h-40 shrink-0 min-w-[250px] w-full box-border p-4 resize-none ${
            !inputEnabled ? 'text-[#A6A9B8]' : 'text-[#4B4B4B]'
          }`}
          placeholder="Indique aquí su justificación"
          value={currentValue}
          disabled={!inputEnabled}
          onChange={handleInputChange}
        />
      </div>
    </div>
  );
};
