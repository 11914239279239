import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { ItemContainer } from '../components/ItemContainer';
import { Audit } from '../types';

export const AuditView = () => {
  const { sectionId } = useParams<{ sectionId: string }>();
  const { data } = useSelector((state: any) => state.data);
  const audits: Audit[] = data.audits.filter((item: Audit) => {
    const [firstDecimals, secondDecimals] = item?.id.toString().split('.');
    return firstDecimals === sectionId && secondDecimals;
  });
  const checkedAudits = data.completedAudits.split(', ');
  const allChecked = checkedAudits.find((item: string) => item === sectionId);

  const renderItemContainer = (audit: any, index: number) => {
    const title = `Directriz ${audit?.id}`;
    return (
      <ItemContainer
        key={`${audit?.id}-${index}`}
        title={title}
        description={audit?.description?.replace(/\n/g, '')}
        onCheckedChange={(_: boolean) => {}}
        checked={
          allChecked ||
          checkedAudits?.some((item: string) => item === audit?.id)
        }
        inputEnabled={false}
      />
    );
  };

  return (
    <>
      <section className="self-stretch flex flex-col items-start justify-start pt-0 pb-[15px] pr-[23px] pl-[22px] box-border gap-4 max-w-full w-auto h-auto text-left text-sm font-body-regular">
        {audits.map((audit, index) => renderItemContainer(audit, index))}
      </section>
    </>
  );
};
