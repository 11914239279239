interface BreadcrumbProps {
  sections: { name: string; action: () => void }[];
}

export const Breadcrumb = ({ sections }: BreadcrumbProps) => {
  return (
    <div className="flex items-center text-lg text-gray-800">
      {sections.map((section, index) => (
        <div key={index} className="max-w-[400px] truncate">
          <span
            className={`cursor-pointer px-2 hover:underline  ${
              index === sections.length - 1 ? 'font-semibold' : ''
            }`}
            onClick={section.action}
          >
            {section.name}
          </span>
          {index < sections.length - 1 && (
            <span className="text-gray-500">/</span>
          )}
        </div>
      ))}
    </div>
  );
};
