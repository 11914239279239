export enum CardType {
  ODS = 'ODS',
  OBJECTIVES = 'OBJECTIVES',
  AUDIT = 'AUDITS',
}

export enum SectionType {
  HOME = 'home',
  ODS = 'ods',
  OBJECTIVES = 'objectives',
  AUDIT = 'audits',
}

export enum UserRole {
  Responsible = 'Responsible',
  Verifier = 'Verifier',
}

export enum FormStatus {
  Pending = 'Pendiente',
  Progress = 'En proceso',
  Rejected = 'Rechazado',
  Completed = 'Completado',
}
