import Header from "../components/Header";
import { useParams } from "react-router-dom";
import { OdsView } from "./OdsView";
import { AuditView } from "./AuditView";
import { ObjectivesView } from "./ObjectivesView";
import NotFound from "./NotFound";
import { SectionType } from "../enums";

const VIEWS: { [key in SectionType]?: React.ComponentType<any> } = {
  [SectionType.ODS]: OdsView,
  [SectionType.AUDIT]: AuditView,
  [SectionType.OBJECTIVES]: ObjectivesView,
};

export const SectionView = () => {
  const { section } = useParams<{ section: SectionType }>();

  const ViewComponent = section && VIEWS[section] ? VIEWS[section] : NotFound;

  return (
    <div className="flex gap-12 w-full h-full min-h-screen flex-col bg-gray-100">
      <div className="w-full h-auto overflow-hidden flex flex-col items-center justify-start py-10 px-5 box-border gap-8 tracking-[normal]">
        <Header section={section as string} />
        {ViewComponent ? <ViewComponent /> : <NotFound />}
      </div>
    </div>
  );
};
