import React from 'react';
import { FormStatus } from '../enums';
import Tooltip from './Tooltip';

interface CircleProps {
  status: FormStatus;
}

const ColorByStatus = {
  [FormStatus.Pending]: '#979797',
  [FormStatus.Progress]: '#01A181',
  [FormStatus.Rejected]: '#FFCB3D',
  [FormStatus.Completed]: '#01A181',
};

const StatusCircle: React.FC<CircleProps> = ({ status }) => {
  const isFilledCircle = () => {
    return status === FormStatus.Completed || status === FormStatus.Rejected;
  };
  const getStatusColor = (): string => {
    if (!isFilledCircle()) {
      return 'white';
    }
    return ColorByStatus[status];
  };

  const getBorderColor = (): string => {
    return ColorByStatus[status];
  };

  return (
    <div>
      <Tooltip message={status}>
        <div
          style={{
            width: '18px',
            height: '18px',
            borderRadius: '50%',
            border: `2px solid ${getBorderColor()}`,
            backgroundColor: getStatusColor(),
          }}
        />
      </Tooltip>
    </div>
  );
};

export default StatusCircle;
