export const SUSTAINABLE_DEVELOPMENT_GOALS =
  'Objetivos de Desarrollo Sostenible';
export const STRATEGIC_OBJECTIVES = 'Objetivos Estratégicos';
export const AUDIT_CRITERIA_AND_GUIDELINES = 'Criterios y Directrices EEES';
export const ODS_TEXT = 'ODS ';
export const AUDIT_TEXT = 'Criterio ';
export const OBJECTIVES_TEXT = 'Objetivo Estratégico: ';
export const SUBTITLE_OBJECTIVES_TEXT =
  'A continuación, se muestran los Objetivos Estratégicos asociados al activo.';
export const SUBTITLE_ODS_TEXT =
  'A continuación, se muestran las Metas del ODS asociadas al activo. Marque las que considere que se estén cumpliendo.';
export const SUBTITLE_AUDIT_TEXT =
  'A continuación, se muestran los Criterios y Directrices EEES asociados al activo.';
