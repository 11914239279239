import { formService } from '../services/form.service';
import { FileUploaded } from '../types';

interface InfoModalProps {
  isOpen: boolean;
  toggleModal: (open: boolean) => void;
  data: {
    period: string;
    estimatedDeliveryDate: string;
    deliveryDate: string;
    responsible: string;
    validator: string;
    files: (File | FileUploaded)[];
  };
}

export const InfoModal = ({ isOpen, toggleModal, data }: InfoModalProps) => {
  const handleClose = () => {
    toggleModal(false);
  };

  const downLoadFile = async (file: FileUploaded) => {
    await formService.downloadFileById(file.id, file.name);
  };

  return (
    <>
      {isOpen && (
        <div className="flex justify-center items-center fixed h-screen w-full z-10">
          <div className="flex items-center justify-center min-h-screen">
            <div
              className="fixed inset-0 transition-opacity"
              aria-hidden="true"
            >
              <div className="absolute inset-0 bg-gray-500 animate-fade-in-modal opacity-75"></div>
            </div>
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              ​
            </span>
            <div className="flex flex-col bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all w-full min-w-full max-w-[750px] px-20 py-10 gap-5 animate-fade-in-down">
              <div>
                <button
                  className=" cursor-pointer absolute top-[45px] font-bold font-body-bold right-[35px] bg-[#E5E5E5] text-[#4F4F4F] rounded-full w-[24px] h-[24px] flex items-center justify-center text-[17px]"
                  onClick={handleClose}
                >
                  <img src="/images/cross.svg" alt="" />
                </button>
              </div>
              <div className="flex flex-col gap-2">
                <div>
                  <h4 className="m-0 text-[#54595E] font-[600] leading-7 font-body-regular text-[20px]">
                    Información sobre el Activo
                  </h4>
                </div>
                <div className="self-stretch flex-1 rounded-lg bg-white flex flex-row items-center justify-start">
                  <div className="flex flex-col">
                    <div className="flex flex-row gap-[5px]">
                      <span className="text-[#949494] font-[700] leading-7 font-body-regular text-[14px]">
                        Periodo
                      </span>
                      <span className="text-[#949494] font-[700] leading-7 font-body-regular text-[14px]">
                        {data.period}
                      </span>
                    </div>
                    <div className="flex flex-row gap-[5px]">
                      <span className="text-[#949494] font-[700] leading-7 font-body-regular text-[14px]">
                        Fecha de entrega estimada:
                      </span>
                      <span className="text-[#949494] font-[700] leading-7 font-body-regular text-[14px]">
                        {data.estimatedDeliveryDate}
                      </span>
                    </div>
                    <div className="flex flex-row gap-[5px]">
                      <span className="text-[#949494] font-[700] leading-7 font-body-regular text-[14px]">
                        Fecha de entrega:
                      </span>
                      <span className="text-[#949494] font-[700] leading-7 font-body-regular text-[14px]">
                        {data.deliveryDate}
                      </span>
                    </div>
                    <div className="flex flex-row gap-[5px]">
                      <span className="text-[#949494] font-[700] leading-7 font-body-regular text-[14px]">
                        Responsable:
                      </span>
                      <span className="text-[#949494] font-[700] leading-7 font-body-regular text-[14px]">
                        {data.responsible}
                      </span>
                    </div>
                    <div className="flex flex-row gap-[5px]">
                      <span className="text-[#949494] font-[700] leading-7 font-body-regular text-[14px]">
                        Validador:
                      </span>
                      <span className="text-[#949494] font-[700] leading-7 font-body-regular text-[14px]">
                        {data.validator}
                      </span>
                    </div>
                    <div className="flex flex-col gap-[5px]">
                      <span className="text-[#949494] font-[700] leading-7 font-body-regular text-[14px]">
                        Ficheros adjuntos:
                      </span>
                      <div className="flex flex-row gap-[3px]">
                        {data.files.map((file) => (
                          <img
                            src="/images/file.svg"
                            alt=""
                            title={file.name}
                            onClick={() => downLoadFile(file as FileUploaded)}
                            className="cursor-pointer"
                          />
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
