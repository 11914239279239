interface BulletListProps {
  title: string;
  items: BulletListItem[];
  onSelected?: (selected: number) => void;
  selectedIndex?: number;
  titleIcon?: string;
}

import { BulletListItem } from '../types';
import './BulletList.css';

export const BulletList = ({
  title,
  items,
  onSelected,
  selectedIndex,
  titleIcon,
}: BulletListProps) => {
  return (
    <>
      <div className="flex items-center relative">
        {titleIcon && (
          <img
            className="h-[40px] w-[40px] mr-[10px] absolute left-[-50px] top-[-8px]"
            src={titleIcon}
          ></img>
        )}
        <h2 className="h-[38px] w-fit m-0 font-avenir font-semibold">
          {title}
        </h2>
      </div>
      <div className="grid grid-cols-2 gap-x-4 gap-y-1 items-center max-h-[230px] overflow-y-auto styled-scrollbar">
        {items.map((item, index) => (
          <section
            key={index}
            className={`fade-in flex flex-row gap-[16px] h-fit items-center ${
              onSelected ? 'cursor-pointer' : ''
            }`}
            onClick={() => onSelected && onSelected(index)}
          >
            {item.active ? (
              <img
                src="/images/filled_graph_circle.svg"
                alt=""
                className="w-5 h-5"
              />
            ) : (
              <img src="/images/circle.svg" alt="" className="w-5 h-5" />
            )}
            <span
              className={`text-[#4B4B4B] font-avenir font-extralight leading-7 text-mid w-fit font-semibold max-w-[260px] ${
                !item.active ? 'opacity-10' : ''
              }`}
              title={item.tooltip}
            >
              {item.phrase}
            </span>
          </section>
        ))}
      </div>
    </>
  );
};
